import styled from "styled-components";
import MissionWrapper from "./MissionWrapper";
import { useMutation } from "@apollo/client";
import { SetPoints } from "functions/queries";
import { unstakeNft } from "functions/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import toast from "react-hot-toast";
import { useEffect, useReducer, useState } from "react";
import BattleMissionViperDetails from "./BattleMissionViperDetails";
import FactionFlag from "./FactionFlag";
import { useGetIsWinner, useGetRank } from "functions/hooks";
import MissionItem from "./MissionItem";
import ClaimModal from "./ClaimModal";

const BattleMission = ({
  loading,
  refreshAll,
  selectedNft,
  stakeNftAction,
  setLoading,
  setSelectedNft,
  totalNfts,
}: {
  loading: boolean;
  refreshAll: Function;
  selectedNft: any;
  stakeNftAction: Function;
  setLoading: Function;
  setSelectedNft: Function;
  totalNfts: number;
}) => {
  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();

  const [viperImage, setViperImage] = useState(selectedNft?.json?.image);
  const [setPoints] = useMutation(SetPoints);
  // const [newPoints, setNewPoints] = useState(0);
  const [show, setShow] = useState(false);

  const [refreshHandle, forceRefresh] = useReducer((x) => x + 1, 0);

  const isWinner = useGetIsWinner(refreshHandle, totalNfts);

  useEffect(() => {
    if (selectedNft) {
      setViperImage(selectedNft?.json?.image);
    }
  }, [selectedNft]);

  const handleClose = () => {
    setShow(false);
  };

  // const handleShow = (totalPoints) => {
  //   setNewPoints(totalPoints);
  //   setShow(true);
  // };

  const unstakeNftAction = async (points) => {
    forceRefresh();
    let hash = selectedNft.json.name.substring(
      selectedNft.json.name.indexOf("#") + 1
    );

    const selectedViperPoints =
      selectedNft?.json?.attributes?.find((el) => el.trait_type === "Points")
        ?.value ?? 0;

    const totalPoints = Number(selectedViperPoints) + Number(points);
    const id = toast.loading("Finishing Mission...");
    setLoading(true);

    const status = await unstakeNft(
      new PublicKey(selectedNft?.farmer?.account?.farm?.toString()),
      connection,
      wallet,
      selectedNft.mint,
      publicKey,
      sendTransaction,
      selectedNft.farmer
    );

    if (status) {
      await refreshAll();
      refreshAll();

      if (status.err !== null) {
        setLoading(false);
        toast.error("Something went wrong, please open a ticket on Discord.", {
          id,
        });
      } else {
        try {
          const { data: result } = await setPoints({
            variables: {
              hash,
              points: totalPoints.toString(),
            },
          });

          if (result?.setPoints?.errors?.length) {
            setLoading(false);
            toast.error(result?.setPoints?.errors[0].message, {
              id,
            });
          } else {
            setLoading(false);
            toast.success(`Mission Finished.`, { id });
            await refreshAll();
            refreshAll();
            forceRefresh();

            // if (isWinner) {
            //   setLoading(false);
            //   toast(`You found a Zenjin Chest...`, { id });
            //   handleShow(totalPoints);
            // } else {
            setSelectedNft(null);
            setLoading(false);
            toast.dismiss();
            // }
          }
        } catch (err) {
          setLoading(false);
          toast.error(
            "Something went wrong, please open a ticket on Discord.",
            {
              id,
            }
          );
        }
      }
    }
  };

  const hasFaction = selectedNft?.json?.attributes?.find(
    (el) => el.trait_type === "Faction"
  );

  const hasRank = selectedNft?.json?.attributes?.find(
    (el) => el.trait_type === "Rank"
  );

  const { rankId } = useGetRank(hasRank?.value);

  const hasPoints = selectedNft?.json?.attributes?.find(
    (el) => el.trait_type === "Points"
  );

  return (
    <>
      <BattleMissionContainer>
        <div style={{ position: "relative" }}>
          {hasFaction && (
            <FactionFlag
              className="viper-faction"
              faction={hasFaction?.value}
              height={60}
            />
          )}
          <ViperImage
            src={viperImage}
            alt={selectedNft?.json?.name}
            className="viper-portait"
          />
          <BattleMissionViperDetails
            rankName={hasRank?.value}
            currentPoints={Number(hasPoints?.value)}
          />
        </div>
        <div style={{ position: "relative" }}>
          <MissionWrapper />
          <MissionsContainer>
            <MissionsBalance>
              <span>Balance:</span>
              <p>{hasPoints ? hasPoints?.value : 0}</p>
            </MissionsBalance>
            <MissionsList>
              {hasRank?.value === "Scout" ? (
                <MissionItem
                  missionSlot={"one"}
                  loading={loading}
                  selectedNft={selectedNft}
                  stakeNftAction={stakeNftAction}
                  unstakeNftAction={unstakeNftAction}
                  farmId={
                    selectedNft?.farmer
                      ? selectedNft?.farmer?.account?.farm
                      : process.env.REACT_APP_FARM_ID_FOUR
                  }
                  title={
                    hasFaction?.value === "The Quetzals"
                      ? "Disrupt Supplies: Nagaz"
                      : hasFaction?.value === "The Salamanderz"
                      ? "Resource Gathering"
                      : hasFaction?.value === "The Nagaz"
                      ? "Warrior’s Path"
                      : hasFaction?.value === "The Aberrantz"
                      ? "Army of the Damned"
                      : hasFaction?.value === "The Hyve"
                      ? "Secrets of the Hyve"
                      : "Mission One"
                  }
                  cost={0}
                  earned={500}
                  duration={5}
                />
              ) : (
                <MissionItem
                  missionSlot={"one"}
                  loading={loading}
                  selectedNft={selectedNft}
                  stakeNftAction={stakeNftAction}
                  unstakeNftAction={unstakeNftAction}
                  farmId={
                    selectedNft?.farmer
                      ? selectedNft?.farmer?.account?.farm
                      : process.env.REACT_APP_FARM_ID_FOUR
                  }
                  title={
                    hasFaction?.value === "The Quetzals"
                      ? "The Sky of the Night"
                      : hasFaction?.value === "The Salamanderz"
                      ? "Disrupt Supplies: Quetzals"
                      : hasFaction?.value === "The Nagaz"
                      ? "Spy Among the Nagaz"
                      : hasFaction?.value === "The Aberrantz"
                      ? "Disrupt Supplies: Salamanderz"
                      : hasFaction?.value === "The Hyve"
                      ? "Prison of the Forgotten"
                      : "Mission One"
                  }
                  cost={0}
                  earned={150}
                  duration={5}
                />
              )}
              {rankId <= 1 ? (
                <MissionsListItem>
                  <p className="mission-title">UNLOCKS IN 1 level</p>
                </MissionsListItem>
              ) : (
                <MissionItem
                  missionSlot={"two"}
                  loading={loading}
                  selectedNft={selectedNft}
                  stakeNftAction={stakeNftAction}
                  unstakeNftAction={unstakeNftAction}
                  farmId={
                    selectedNft?.farmer
                      ? selectedNft?.farmer?.account?.farm
                      : process.env.REACT_APP_FARM_ID_FOUR
                  }
                  title={
                    hasFaction?.value === "The Quetzals"
                      ? "For Queen and Glory!"
                      : hasFaction?.value === "The Salamanderz"
                      ? "Souls for Zenjin"
                      : hasFaction?.value === "The Nagaz"
                      ? "Disrupt Supplies: Hyve"
                      : hasFaction?.value === "The Aberrantz"
                      ? "No Rest For the Dead"
                      : hasFaction?.value === "The Hyve"
                      ? "Disrupt Supplies: Aberrantz"
                      : "Mission Two"
                  }
                  cost={100}
                  earned={350}
                  duration={5}
                />
              )}
              {rankId <= 4 ? (
                <MissionsListItem>
                  <p className="mission-title">UNLOCKS IN {5 - rankId} RANKS</p>
                </MissionsListItem>
              ) : rankId >= 6 ? (
                <MissionItem
                  missionSlot={"three"}
                  loading={loading}
                  selectedNft={selectedNft}
                  stakeNftAction={stakeNftAction}
                  unstakeNftAction={unstakeNftAction}
                  farmId={process.env.REACT_APP_FARM_ID_FOUR}
                  title={
                    hasFaction?.value === "The Quetzals"
                      ? "Giving It All We've Got"
                      : hasFaction?.value === "The Salamanderz"
                      ? "An Abuse of Power"
                      : hasFaction?.value === "The Nagaz"
                      ? "Putting A Plan Together"
                      : hasFaction?.value === "The Aberrantz"
                      ? "Things Best Kept Dark"
                      : hasFaction?.value === "The Hyve"
                      ? "Return Lost Souls"
                      : "Mission Three"
                  }
                  cost={250}
                  earned={750}
                  duration={7}
                />
              ) : (
                <MissionItem
                  missionSlot={"three"}
                  loading={loading}
                  selectedNft={selectedNft}
                  stakeNftAction={stakeNftAction}
                  unstakeNftAction={unstakeNftAction}
                  farmId={
                    selectedNft?.farmer
                      ? selectedNft?.farmer?.account?.farm
                      : process.env.REACT_APP_FARM_ID_FOUR
                  }
                  title={
                    hasFaction?.value === "The Quetzals"
                      ? "Balance of Power"
                      : hasFaction?.value === "The Salamanderz"
                      ? "A Theif's Reward"
                      : hasFaction?.value === "The Nagaz"
                      ? "One Step Closer"
                      : hasFaction?.value === "The Aberrantz"
                      ? "The Power Within"
                      : hasFaction?.value === "The Hyve"
                      ? "Prison of the Forgotten"
                      : "Mission Three"
                  }
                  cost={250}
                  earned={750}
                  duration={7}
                />
              )}
              {rankId <= 7 ? (
                <MissionsListItem>
                  <p className="mission-title">UNLOCKS IN {8 - rankId} RANKS</p>
                </MissionsListItem>
              ) : (
                <MissionsListItem>
                  <p className="mission-title">
                    UNLOCK YOUR FACTION LEADER FIRST
                  </p>
                </MissionsListItem>
              )}
              {rankId <= 10 ? (
                <MissionsListItem>
                  <p className="mission-title">
                    UNLOCKS IN {11 - rankId} RANKS
                  </p>
                </MissionsListItem>
              ) : (
                <MissionsListItem>
                  <p className="mission-title">Mission 5</p>
                </MissionsListItem>
              )}
            </MissionsList>
          </MissionsContainer>
        </div>
      </BattleMissionContainer>
      {show && (
        <ClaimModal
          show={show}
          handleClose={handleClose}
          isWinner={isWinner}
          selectedNft={selectedNft}
          setSelectedNft={setSelectedNft}
          refreshAll={refreshAll}
          newPoints={0}
          totalNfts={totalNfts}
        />
      )}
    </>
  );
};

export default BattleMission;

const MissionsList = styled.div``;

const MissionsListItem = styled.div`
  align-items: center;
  background: rgba(113, 119, 123, 0.5);
  border-radius: 2px;
  box-shadow: 2px 2px 0 #101112;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 74px;
  }

  .mission-title {
    color: #ffffff;
    font-family: "ROTORcap Neue Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    text-shadow: 0px 1px 1px #4e5256;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
`;

const BattleMissionContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  margin-bottom: 50px;
  position: relative;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 452px 618px;
  }

  .viper-portait {
    margin-bottom: 30px;
  }

  .viper-faction {
    left: 12px;
    position: absolute;
    top: 12px;
  }

  .viper-animate {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  span {
    display: block;
    font-family: "ROTORcap Neue Bold";
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
    opacity: 0.75;
  }

  p {
    font-family: "ROTORcap Neue Bold";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
  }
`;

const ViperImage = styled.img`
  object-fit: contain;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 452px;
    width: 452px;
  }
`;

const MissionsContainer = styled.div`
  height: 100%;
  padding: 30px 30px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding: 50px 60px;
  }
`;

const MissionsBalance = styled.div`
  align-items: center;
  background: #0f0f10;
  border: 1px solid #b9964f;
  display: flex;
  height: 32px;
  justify-content: space-around;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 160px;

  @media only screen and (min-width: 768px) {
    height: 40px;
    margin-bottom: 30px;
    width: 200px;
  }

  span {
    color: #b9964f;
    font-family: "ROTORcap Neue Bold";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    margin: 0 !important;

    @media only screen and (min-width: 768px) {
      font-size: 16px !important;
    }
  }

  p {
    font-family: "ROTORcap Neue Bold";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    margin: 0;

    @media only screen and (min-width: 768px) {
      font-size: 20px !important;
    }
  }
`;
