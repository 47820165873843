import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import styled from "styled-components";

import Logo from "../images/logo.png";

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <a href="/">
          <img src={Logo} height={80} alt={"Zenjin Viperz"} />
        </a>
        <div style={{ display: "flex" }}>
          <HeroLinks>
            <HeroLink href="https://www.zenjinviperz.io">Home</HeroLink>
            <HeroLink href="https://rafflez.zenjinviperz.io">Rafflez</HeroLink>
            <HeroLink href="https://factions.zenjinviperz.io">
              Factions
            </HeroLink>
            <HeroLink href="#">Missions</HeroLink>
            <HeroLink href="https://bottles.zenjinviperz.io">Bottles</HeroLink>
            <HeroLink href="https://snek.zenjinviperz.io" target={"_blank"}>
              Whitepaper
            </HeroLink>
          </HeroLinks>
          <WalletMultiButton />
        </div>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
`;

const HeroLinks = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;
  position: relative;
  z-index: 5;

  @media only screen and (max-width: 768px) {
    a {
      display: none;
    }
  }
`;

export const HeroLink = styled.a`
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;
