import { programs } from "@metaplex/js";
import { BN, Wallet } from "@project-serum/anchor";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import {
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { findWhitelistProofPDA } from "gem-sdk";
import toast from "react-hot-toast";
import {
  fetchFarm,
  fetchFarmer,
  initGemBank,
  initGemFarm,
  NFTData,
} from "./staking";

const signAndSendAll = async (
  connection: Connection,
  txs: Transaction[],
  sendTransaction: Function,
  signAllTransactions: Function
) => {
  if (signAllTransactions !== undefined) {
    const signed_txs = await signAllTransactions(txs);

    const txids = [];
    for (const tx of signed_txs) {
      txids.push(await connection.sendRawTransaction(tx.serialize()));
    }

    for (const txid of txids) {
      await connection.confirmTransaction(txid);
    }
  } else {
    const tx_sig_list = [];
    for (const tx of txs) {
      tx_sig_list.push(await sendTransaction(tx, connection));
    }

    for (const txid of tx_sig_list) {
      await connection.confirmTransaction(txid);
    }
  }
};

const claimSnek = async (
  farmId: PublicKey,
  connection: Connection,
  wallet: any | null,
  mint: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  confirm: boolean = true
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const farmAcc = await fetchFarm(
    farmId,
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  if (farmAcc === null) return;

  const { tx: txClaim } = await gf.claim(
    new PublicKey(farmId),
    publicKey!,
    new PublicKey(farmAcc.rewardA.rewardMint!),
    new PublicKey(farmAcc.rewardB.rewardMint!),
    mint
  );

  const txs = new Transaction().add(txClaim);

  if (!confirm) {
    return txs;
  }

  try {
    const txid = await sendTransaction(txs, connection);
    await connection.confirmTransaction(txid);
  } catch (e) {
    throw e;
  }
};

export const claimAll = async (
  farmId: PublicKey,
  connection: Connection,
  wallet: Wallet | null,
  publicKey: PublicKey,
  sendTransaction: Function,
  signAllTransactions: any,
  stakedNfts: NFTData[]
) => {
  const txs = [];
  let blockhashObj = await connection.getLatestBlockhash();

  for (const nft of stakedNfts) {
    const tx = (await claimSnek(
      farmId,
      connection,
      wallet,
      nft.mint,
      publicKey,
      sendTransaction,
      false
    ))!;
    tx.feePayer = publicKey;
    tx.recentBlockhash = blockhashObj.blockhash;
    txs.push(tx);
  }

  await signAndSendAll(connection, txs, sendTransaction, signAllTransactions);
};

const stakeNft = async (
  farmId: PublicKey,
  connection,
  wallet,
  mint: PublicKey,
  source: PublicKey,
  creator: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  upgradeViperReward: Function,
  nft: NFTData,
  snek: number,
  confirm: boolean = true
) => {
  const { data: result } = await upgradeViperReward({
    variables: {
      mintId: nft?.mint?.toString(),
      reward: snek,
    },
  });

  if (result?.upgradeViperReward?.error) {
    throw result?.upgradeViperReward?.error;
  } else {
    const mission = missionRank(nft);

    const gf = await initGemFarm(
      connection,
      wallet!.adapter as SignerWalletAdapter
    );

    const gb = await initGemBank(
      connection,
      wallet!.adapter as SignerWalletAdapter
    );

    const txs = new Transaction();
    const farmer = await fetchFarmer(
      farmId,
      connection,
      wallet!.adapter as SignerWalletAdapter,
      publicKey!,
      mint
    );

    const farm = await fetchFarm(
      farmId,
      connection,
      wallet!.adapter as SignerWalletAdapter
    );

    let farmerVault: PublicKey;
    if (farmer === null) {
      // Initializes the farmer if it doesn't exist
      const { tx: createFarmerIx, vault } = await gf!.initFarmer(
        farmId,
        publicKey!,
        publicKey!,
        mint
      );
      txs.add(createFarmerIx);

      farmerVault = vault;
    } else {
      farmerVault = farmer.farmerAcc.vault;
    }

    const [mintProof] = await findWhitelistProofPDA(farm.bank, mint);

    const [creatorProof] = await findWhitelistProofPDA(farm.bank, creator);

    const metadata = await programs.metadata.Metadata.getPDA(mint);

    if (publicKey) {
      if (farmer !== null && farmer.farmerState === "staked") {
        const { tx: txDepositAndStake } = await gf!.flashDeposit(
          farmId,
          publicKey!,
          new BN(1),
          mint,
          mintProof,
          metadata,
          creatorProof
        );

        txs.add(txDepositAndStake);
      } else {
        const { tx: txDepositIx } = await gb.depositGem(
          farm.bank,
          farmerVault,
          publicKey!,
          new BN(1),
          mint,
          source,
          mintProof,
          metadata,
          creatorProof
        );

        txs.add(txDepositIx);

        const { tx: txStakeIx } = await gf!.stake(farmId, publicKey!, mint);
        txs.add(txStakeIx);
      }
    }

    if (mission) {
      txs.add(
        new TransactionInstruction({
          keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
          data: Buffer.from(`${mission ? `Mission: ${mission}` : ""}`, "utf-8"),
          programId: new PublicKey(
            "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
          ),
        })
      );
    }

    if (!confirm) {
      return txs;
    }

    try {
      const txid = await sendTransaction(txs, connection);
      await connection.confirmTransaction(txid);
    } catch (e) {
      throw e;
    }
  }
};

export const stakeAll = async (
  farmId: PublicKey,
  connection: Connection,
  wallet: any | null,
  publicKey: PublicKey,
  sendTransaction: Function,
  signAllTransactions: any,
  unstakedNfts: NFTData[],
  upgradeViperReward: Function,
  id: string
) => {
  const txs = [];

  let blockhashObj = await connection.getLatestBlockhash();
  for (const nft of unstakedNfts) {
    toast.loading(
      `Starting Viperz on Missions... (${unstakedNfts.indexOf(nft) + 1}/${
        unstakedNfts.length
      })`,
      { id }
    );
    const snek = snekEarn(nft);

    if (snek) {
      const tx = (await stakeNft(
        farmId,
        connection,
        wallet,
        nft.mint,
        nft.source,
        nft.creator,
        publicKey,
        sendTransaction,
        upgradeViperReward,
        nft,
        snek,
        false
      ))!;

      tx.feePayer = publicKey;
      tx.recentBlockhash = blockhashObj.blockhash;
      txs.push(tx);
    }
  }

  await signAndSendAll(connection, txs, sendTransaction, signAllTransactions);

  return txs;
};

export const snekEarn = (nft) => {
  const rank = nft?.json?.attributes?.find(
    (el) => el.trait_type === "Rank"
  )?.value;

  if (!rank) {
    return;
  } else {
    if (
      rank === "Scout" ||
      rank === "Grunt" ||
      rank === "Sergeant" ||
      rank === "Senior Sergeant" ||
      rank === "First Sergeant"
    ) {
      return 5;
    } else if (
      rank === "Stone Guard" ||
      rank === "Blood Guard" ||
      rank === "Legionnaire" ||
      rank === "Centurion"
    ) {
      return 7;
    } else if (
      rank === "Champion" ||
      rank === "Lieutenant General" ||
      rank === "General"
    ) {
      return 10;
    } else if (rank === "Warlord") {
      return 12;
    } else if (rank === "High Warlord") {
      return 15;
    }
  }
};

export const missionRank = (nft) => {
  const rank = nft?.json?.attributes?.find(
    (el) => el.trait_type === "Rank"
  )?.value;

  if (!rank) {
    return;
  } else {
    if (rank === "Scout") {
      return "one";
    } else if (
      rank === "Grunt" ||
      rank === "Sergeant" ||
      rank === "Senior Sergeant"
    ) {
      return "two";
    } else if (
      rank === "First Sergeant" ||
      rank === "Stone Guard" ||
      rank === "Blood Guard" ||
      rank === "Legionnaire" ||
      rank === "Centurion" ||
      rank === "Champion" ||
      rank === "Lieutenant General" ||
      rank === "General"
    ) {
      return "three";
    }
  }
};
