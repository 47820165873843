import Colbs from "../images/colbs.gif";

const Loading = ({ type }: { type: string }) => {
  return (
    <div
      style={{
        alignItems: type !== "column" ? "center" : "start",
        background: type !== "column" ? "rgba(35, 31, 61,0.75)" : "transparent",
        display: "flex",
        height: type !== "column" ? "100vh" : "auto",
        justifyContent: "center",
        position: type !== "column" ? "fixed" : "relative",
        width: type !== "column" ? "100vw" : "100%",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 10,
      }}
    >
      <img src={Colbs} alt="Loading" />
    </div>
  );
};

export default Loading;
