import QuetzalFlag from "../images/flag-quetzal.png";
import SalamanderzFlag from "../images/flag-ophis.png";
import NagaFlag from "../images/flag-naga.png";
import AbberantFlag from "../images/flag-aberrants.png";
import HyveFlag from "../images/flag-hyve.png";

const FactionFlag = ({
  className,
  faction,
  height,
}: {
  className: string;
  faction: string;
  height: number;
}) => {
  return (
    <img
      src={
        faction === "The Quetzals"
          ? QuetzalFlag
          : faction === "The Salamanderz"
          ? SalamanderzFlag
          : faction === "The Nagaz"
          ? NagaFlag
          : faction === "The Aberrantz"
          ? AbberantFlag
          : faction === "The Hyve"
          ? HyveFlag
          : null
      }
      height={height}
      alt={faction}
      title={faction}
      className={className}
    />
  );
};

export default FactionFlag;
