import { programs } from "@metaplex/js";
import { BN, Wallet } from "@project-serum/anchor";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import {
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import axios from "axios";
import {
  findFarmerPDA,
  findWhitelistProofPDA,
  GemBankClient,
  GemFarmClient,
  GEM_BANK_PROG_ID,
  GEM_FARM_PROG_ID,
} from "../gem-sdk";
import { MetadataDataData } from "@metaplex-foundation/mpl-token-metadata";

import { IDL as GemBank } from "../gem-sdk/types/gem_bank";
import { IDL as GemFarm } from "../gem-sdk/types/gem_farm";

const {
  metadata: { Metadata },
} = programs;

const creatorId = process.env.REACT_APP_CREATOR_ID;

const bankIdl = GemBank;
// TODO require the correct farm idl (explained in documentation)
const farmIdl = GemFarm;

export interface NFTData {
  mint: PublicKey;
  data: MetadataDataData;
  json: any;
  source: PublicKey;
  creator: PublicKey;
  farmer?: any;
  isStaked?: boolean;
}

export const initGemBank = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemBankClient(
    conn,
    wallet as unknown as Wallet,
    bankIdl as any,
    GEM_BANK_PROG_ID
  );
};

export const initGemFarm = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemFarmClient(
    conn,
    wallet as unknown as Wallet,
    farmIdl as any,
    GEM_FARM_PROG_ID,
    bankIdl as any,
    GEM_BANK_PROG_ID
  );
};

export const fetchFarm = async (
  farmId: PublicKey,
  connection: Connection,
  walletAdapter: SignerWalletAdapter
): Promise<any> => {
  const gf = await initGemFarm(connection, walletAdapter);
  return await gf!.fetchFarmAcc(farmId);
};

export const fetchFarmer = async (
  farmId: PublicKey,
  connection: Connection,
  walletAdapter: SignerWalletAdapter,
  farmer: PublicKey,
  mint: PublicKey
): Promise<any> => {
  const gf = await initGemFarm(connection, walletAdapter);

  const [farmerPDA] = await findFarmerPDA(farmId, farmer, mint);

  try {
    const farmerIdentity = farmer.toBase58();
    const farmerAcc = await gf!.fetchFarmerAcc(farmerPDA);
    const farmerState = gf!.parseFarmerState(farmerAcc);

    return { farmerIdentity, farmerAcc, farmerState };
  } catch (e) {
    console.log({ e });
    return null;
  }
};

// const getFarmer = async (
//   farmId: PublicKey,
//   mint: PublicKey,
//   publicKey: PublicKey,
//   allFarmersFromWallet: any
// ) => {
//   if (!publicKey) return;

//   const [farmerPDA] = await findFarmerPDA(farmId, publicKey, mint);
//   return allFarmersFromWallet.find(
//     (x) => x.publicKey.toBase58() === farmerPDA.toBase58()
//   );
// };

export async function getNFTsByOwner(conn: Connection, owner: PublicKey) {
  const tokens = await getTokensByOwner(conn, owner);

  return tokens;
}

export async function getBottleNfts(conn: Connection, owner: PublicKey) {
  return await getFilledBottles(conn, owner);
}

export async function getFilledBottles(conn: Connection, owner: PublicKey) {
  const walletNfts = await Metadata.findDataByOwner(conn, owner);

  const nfts = [];
  for (let nft of walletNfts)
    if (
      nft?.data?.creators &&
      nft?.data?.creators[0]?.verified &&
      nft?.data?.creators[0]?.address ===
        "ZVCTiaV4acCjTHk8s9z99PuUP5L3bD4or8iaZeGHSkD"
    )
      nfts.push({
        mint: new PublicKey(nft.mint),
        data: nft.data,
        json: await fetch(nft.data.uri)
          .then((e) => e.json())
          .catch((e) => console.log(e)),
      });

  let collator = new Intl.Collator(undefined, { numeric: true });
  nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));

  return nfts.filter(function (el) {
    const trait = el.json?.attributes?.find((item) => item.value !== "Potion");
    if (trait?.value === "Filled") {
      return el;
    } else {
      return null;
    }
  });
}

export async function getTokensByOwner(conn: Connection, owner: PublicKey) {
  const walletNfts = await Metadata.findDataByOwner(conn, owner);

  const nfts = [];
  for (let nft of walletNfts)
    if (
      nft?.data?.creators &&
      nft?.data?.creators[0]?.verified &&
      nft?.data?.creators[0]?.address === creatorId
    )
      nfts.push({
        mint: new PublicKey(nft.mint),
        data: nft.data,
        json: await fetch(nft.data.uri, { cache: "no-cache" })
          .then((e) => e.json())
          .catch((e) => console.log(e)),
      });

  let collator = new Intl.Collator(undefined, { numeric: true });
  nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));

  return nfts;
}

export async function populateVaultNFTs(
  connection: Connection,
  walletAdapter: SignerWalletAdapter,
  farmer
) {
  let currentVaultNFTs = [];

  let gb = await initGemBank(connection, walletAdapter);

  const vault = farmer.account.vault;

  let foundGDRs = await gb.fetchAllGdrPDAs(vault);
  if (foundGDRs && foundGDRs.length) {
    let mints = foundGDRs.map((gdr) => {
      return { mint: gdr.account.gemMint };
    });
    currentVaultNFTs = await getNFTMetadataForMany(mints, connection);
    let collator = new Intl.Collator(undefined, { numeric: true });
    currentVaultNFTs.sort((a, b) =>
      collator.compare(a.externalMetadata.name, b.externalMetadata.name)
    );
    return currentVaultNFTs;
  }
}

export async function getNFTMetadataForMany(tokens, conn) {
  const promises = [];
  // let returnedNfts = []
  tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);

  const filteredNfts = nfts?.filter((nft) => {
    if (
      nft?.onchainMetadata?.data?.creators &&
      nft?.onchainMetadata?.data?.creators[0]?.verified &&
      nft?.onchainMetadata?.data?.creators[0]?.address === creatorId
    ) {
      return nft;
    } else {
      return null;
    }
  });

  return filteredNfts;
}

async function getNFTMetadata(mint, conn, pubkey) {
  try {
    const metadataPDA = await Metadata.getPDA(mint);
    const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
    const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;
    return {
      pubkey: pubkey ? new PublicKey(pubkey) : undefined,
      mint: new PublicKey(mint),
      onchainMetadata,
      externalMetadata,
    };
  } catch (e) {
    // console.log(`failed to pull metadata for token ${mint}`);
  }
}

export const stakeNft = async (
  farmId: PublicKey,
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  associatedAddress: PublicKey,
  creator: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  mission: string
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const gb = await initGemBank(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  const txs = new Transaction();
  const farmer = await fetchFarmer(
    farmId,
    connection,
    wallet!.adapter as SignerWalletAdapter,
    publicKey!,
    mint
  );

  const farm = await fetchFarm(
    farmId,
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  let farmerVault: PublicKey;
  if (farmer === null) {
    // Initializes the farmer if it doesn't exist
    const { tx: createFarmerIx, vault } = await gf!.initFarmer(
      farmId,
      publicKey!,
      publicKey!,
      mint
    );
    txs.add(createFarmerIx);

    farmerVault = vault;
  } else {
    farmerVault = farmer.farmerAcc.vault;
  }

  const [mintProof] = await findWhitelistProofPDA(farm.bank, mint);

  const [creatorProof] = await findWhitelistProofPDA(farm.bank, creator);

  const metadata = await programs.metadata.Metadata.getPDA(mint);

  if (publicKey) {
    if (farmer !== null && farmer.farmerState === "staked") {
      const { tx: txDepositAndStake } = await gf!.flashDeposit(
        farmId,
        publicKey!,
        new BN(1),
        mint,
        associatedAddress,
        mintProof,
        metadata,
        creatorProof
      );

      txs.add(txDepositAndStake);
    } else {
      const { tx: txDepositIx } = await gb.depositGem(
        farm.bank,
        farmerVault,
        publicKey!,
        new BN(1),
        mint,
        associatedAddress,
        mintProof,
        metadata,
        creatorProof
      );

      txs.add(txDepositIx);

      const { tx: txStakeIx } = await gf!.stake(farmId, publicKey!, mint);
      txs.add(txStakeIx);
    }
  }

  if (mission) {
    txs.add(
      new TransactionInstruction({
        keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
        data: Buffer.from(`${mission ? `Mission: ${mission}` : ""}`, "utf-8"),
        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
      })
    );
  }

  try {
    let blockhashObj = await connection.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = publicKey!;

    const txid = await sendTransaction(txs, connection);
    // if (!confirm) return txid;
    let done = false;
    let status = {
      slot: 0,
      confirmations: 0,
      err: null,
    };
    // let subId = 0;

    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Unstakes a NFT
 * @param farmId - the public key of the farm
 * @param mint   - the mint public key of the nft
 */
export const unstakeNft = async (
  farmId: PublicKey,
  connection,
  wallet,
  mint: PublicKey,
  publicKey: PublicKey,
  sendTransaction: Function,
  farmer
) => {
  const gf = await initGemFarm(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  const gb = await initGemBank(
    connection,
    wallet!.adapter as SignerWalletAdapter
  );
  const farmAcc = await fetchFarm(
    farmId,
    connection,
    wallet!.adapter as SignerWalletAdapter
  );

  // There's two calls to unstake, the first "unstakes" it
  const { tx: txUnstake } = await gf!.unstake(farmId, publicKey!, mint);
  // Then, the second ends the cooldown period
  const { tx: txCooldown } = await gf!.unstake(farmId, publicKey!, mint);
  // Then and only then we can withdraw the gem
  const { tx: txWithdraw } = await gb!.withdrawGem(
    farmAcc.bank,
    farmer!.account.vault,
    publicKey!,
    new BN(1),
    mint,
    publicKey!
  );

  const { tx: txClaim } = await gf.claim(
    new PublicKey(farmId),
    publicKey!,
    new PublicKey(farmAcc.rewardA.rewardMint!),
    new PublicKey(farmAcc.rewardB.rewardMint!),
    mint
  );

  const txs = new Transaction()
    .add(txUnstake)
    .add(txCooldown)
    .add(txWithdraw)
    .add(txClaim);

  try {
    let blockhashObj = await connection.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = publicKey!;

    const txid = await sendTransaction(txs, connection);
    // if (!confirm) return txid;
    let done = false;
    let status = {
      slot: 0,
      confirmations: 0,
      err: null,
    };
    // let subId = 0;

    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    console.log(e);
  }
};

export const checkStatus = async (connection, txid: string) => {
  let done = false;
  let status = {
    slot: 0,
    confirmations: 0,
    err: null,
  };

  try {
    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    return e;
  }
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
