import { gql } from "@apollo/client";

export const SetPointsNoRank = gql`
  mutation SetPointsNoRank($hash: String!, $points: String!) {
    setPointsNoRank(hash: $hash, points: $points) {
      errors {
        field
        message
      }
      txSig
    }
  }
`;

export const SetPoints = gql`
  mutation SetPoints($points: String!, $hash: String!) {
    setPoints(points: $points, hash: $hash) {
      errors {
        field
        message
      }
      txSig
    }
  }
`;

export const UseBottleAnimate = gql`
  mutation UseBottleAnimate($mintId: String!, $bottleId: String!) {
    useBottleAnimate(mintId: $mintId, bottleId: $bottleId) {
      errors {
        field
        message
      }
      txSig
      image
    }
  }
`;

export const UpgradeViperReward = gql`
  mutation UpgradeViperReward($reward: Float!, $mintId: String!) {
    upgradeViperReward(reward: $reward, mintId: $mintId) {
      errors {
        field
        message
      }
      txSig
    }
  }
`;
