import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features";

import { createRoot } from "react-dom/client";

import { Router } from "react-router-dom";

import App from "./App";

import history from "./services/history";
import { Toaster } from "react-hot-toast";

import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import "./index.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import { client } from "apolloClient";
import { ApolloProvider } from "@apollo/client";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <>
    <Toaster
      toastOptions={{
        success: {
          duration: 5000,
        },
      }}
    />
    <Toaster />
    <Router history={history}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
