import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { PublicKey } from "@solana/web3.js";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Hero = ({ publicKey }: { publicKey: PublicKey }) => {
  return (
    <HeroContainer>
      <Container>
        <VerticalGrid>
          <FactionIntro>
            <h2 className="h2">BATTLE MISSIONZ</h2>
            <p>
              Are you Zenjin enough? Every day Viperz risk their lives in Battle
              Missions, trying to earn tokens and battle points that will
              differentiate themselves from the weak and the unworthy. Do you
              have what it takes to claim your spot in Zenjin lore?
            </p>
            <p>
              You may have it, you may not, but one thing is for sure; your next
              mission may be your last, so tread carefully warrior.
            </p>
            {!publicKey && <p>Connect your wallet to start.</p>}
          </FactionIntro>
        </VerticalGrid>
      </Container>
      {!publicKey && (
        <WalletContainer>
          <WalletMultiButton />
        </WalletContainer>
      )}
    </HeroContainer>
  );
};

export default Hero;

const WalletContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 5;
`;

const VerticalGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FactionIntro = styled.div`
  max-width: 590px;
  position: relative;
  text-align: center;
  z-index: 5;
`;

const HeroContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
`;
