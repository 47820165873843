import styled from "styled-components";

import BottomLeftCorner from "../images/bottom-left-corner.png";
import BottomRightCorner from "../images/bottom-right-corner.png";
import TopLeftCorner from "../images/top-left-corner.png";
import TopRightCorner from "../images/top-right-corner.png";

import BottomBorder from "../images/bottom-corner.png";
import TopBorder from "../images/top-corner.png";
import LeftBorder from "../images/left-corner.png";
import RightBorder from "../images/right-corner.png";

const MissionWrapper = () => {
  return (
    <>
      <MissionCorner
        src={TopLeftCorner}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 2,
        }}
        alt="Mission Table"
      />
      <MissionCorner
        src={TopRightCorner}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 2,
        }}
        alt="Mission Table"
      />
      <MissionCorner
        src={BottomLeftCorner}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 2,
        }}
        alt="Mission Table"
      />
      <MissionCorner
        src={BottomRightCorner}
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 2,
        }}
        alt="Mission Table"
      />
      <MissionBorderHoriz
        style={{
          background: `url(${TopBorder})`,
          height: 32,
          left: 10,
          right: 10,
          top: 0,
          position: "absolute",
        }}
      />
      <MissionBorderVert
        style={{
          background: `url(${LeftBorder})`,
          bottom: 35,
          left: 0,
          position: "absolute",
          top: 10,
          width: 32,
        }}
      />
      <MissionBorderVert
        style={{
          background: `url(${RightBorder})`,
          bottom: 35,
          position: "absolute",
          right: 0,
          top: 10,
          width: 32,
        }}
      />
      <MissionBorderHoriz
        style={{
          background: `url(${BottomBorder})`,
          height: 32,
          bottom: 0,
          left: 10,
          right: 10,
          position: "absolute",
        }}
      />
    </>
  );
};

export default MissionWrapper;

const MissionCorner = styled.img`
  @media only screen and (max-width: 768px) {
    height: 62px;
  }
`;
const MissionBorderHoriz = styled.div`
  @media only screen and (max-width: 768px) {
    background-size: 16px 100% !important;
    height: 16px !important;
  }
`;

const MissionBorderVert = styled.div`
  @media only screen and (max-width: 768px) {
    background-size: 100% 16px !important;
    width: 16px !important;
  }
`;
