import { useGetRank } from "functions/hooks";

import RankOne from "../images/ranks/1.png";
import RankTwo from "../images/ranks/2.png";
import RankThree from "../images/ranks/3.png";
import RankFour from "../images/ranks/4.png";
import RankFive from "../images/ranks/5.png";
import RankSix from "../images/ranks/6.png";
import RankSeven from "../images/ranks/7.png";
import RankEight from "../images/ranks/8.png";
import RankNine from "../images/ranks/9.png";
import RankTen from "../images/ranks/10.png";
import RankEleven from "../images/ranks/11.png";
import RankTwelve from "../images/ranks/12.png";
import RankThirteen from "../images/ranks/13.png";
import RankFourteen from "../images/ranks/14.png";

const BattleRank = ({
  className,
  rankName,
  size,
}: {
  className?: string;
  rankName: string;
  size: number;
}) => {
  const { rankId } = useGetRank(rankName);

  return (
    <>
      <img
        src={
          rankId === 1
            ? RankOne
            : rankId === 2
            ? RankTwo
            : rankId === 3
            ? RankThree
            : rankId === 4
            ? RankFour
            : rankId === 5
            ? RankFive
            : rankId === 6
            ? RankSix
            : rankId === 7
            ? RankSeven
            : rankId === 8
            ? RankEight
            : rankId === 9
            ? RankNine
            : rankId === 10
            ? RankTen
            : rankId === 11
            ? RankEleven
            : rankId === 12
            ? RankTwelve
            : rankId === 13
            ? RankThirteen
            : rankId === 14
            ? RankFourteen
            : RankOne
        }
        alt={rankName}
        className={className}
        height={size}
        width={size}
      />
    </>
  );
};

export default BattleRank;
