import dayjs from "dayjs";
import { useGetRank, useTokensEarnt } from "functions/hooks";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import ProgressBar from "react-bootstrap/ProgressBar";
import FactionFlag from "./FactionFlag";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";

const Viper = ({
  nft,
  selectedNft,
  setSelectedNft,
  type,
}: {
  nft: any;
  selectedNft: any;
  setSelectedNft: Function;
  type: string;
}) => {
  const { connection } = useConnection();
  const image = nft?.json ? nft?.json?.image : nft?.image;
  const name = nft?.json ? nft?.json?.name : nft?.name;

  const [dateStaked, setDateStaked] = useState(null);
  const [missionSig, setMissionSig] = useState(null);

  const hasFaction = nft?.json?.attributes?.find(
    (el) => el.trait_type === "Faction"
  );

  const hasRank = nft?.json?.attributes?.find((el) => el.trait_type === "Rank");
  const { rankIcon, rankSnek } = useGetRank(hasRank?.value);
  const earnt: number = useTokensEarnt(nft.farmer, rankSnek, type);

  useEffect(() => {
    if (type === "staked") {
      setDateStaked(dayjs.unix(Number(nft?.farmer?.account?.minStakingEndsTs)));
    }
  }, [nft?.farmer?.account?.minStakingEndsTs, type]);

  const setSelectedNftAction = () => {
    setSelectedNft(nft);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    async function fetchTransactionDate() {
      try {
        if (nft?.farmer) {
          const sigs = await connection.getSignaturesForAddress(
            new PublicKey(nft?.farmer?.publicKey?.toString()),
            {
              limit: 1,
            }
          );

          const trans = await connection.getParsedTransaction(
            sigs[0].signature
          );

          if (
            trans?.meta?.logMessages?.includes(
              "Program log: Instruction: Stake"
            )
          ) {
            setMissionSig(sigs[0].memo ? sigs[0].memo.substring(14) : null);
          } else {
            const newSigs = await connection.getSignaturesForAddress(
              new PublicKey(nft?.farmer?.publicKey?.toString()),
              {
                limit: 10,
              }
            );
            let newArray = [];
            for (var i = 0; i < newSigs.length; i++) {
              var value = newSigs[i];
              const trans = await connection.getParsedTransaction(
                value.signature
              );
              if (
                trans &&
                trans?.meta?.logMessages?.includes(
                  "Program log: Instruction: Stake"
                )
              ) {
                newArray.push(newSigs[i]);
              }
            }
            const data = newArray[0];

            if (data) {
              setMissionSig(data.memo.substring(14));
            } else {
              setMissionSig(sigs[0].memo.substring(14));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchTransactionDate();
  }, [connection, nft]);

  const mission =
    nft?.farmer?.account?.farm?.toString() === process.env.REACT_APP_FARM_ID_ONE
      ? "one"
      : nft?.farmer?.account?.farm?.toString() ===
        process.env.REACT_APP_FARM_ID_TWO
      ? "two"
      : "three";

  const perc =
    (dayjs(new Date()).diff(dateStaked, "seconds") /
      ((missionSig
        ? missionSig !== "three"
          ? 5
          : 7
        : mission !== "three"
        ? 5
        : 7) *
        60 *
        60 *
        24)) *
      100 ?? 0;

  return (
    <>
      <ViperContainer
        key={nft?.mint?.toString()}
        onClick={() =>
          hasRank
            ? setSelectedNftAction()
            : toast.error("You need to choose a faction first...")
        }
        className={selectedNft === nft && "active"}
      >
        <FactionFlag
          className="viper-faction"
          faction={hasFaction?.value}
          height={40}
        />
        <ViperImage src={image} alt={name} title={name} />
        {type === "staked" && (
          <ProgressBar now={perc} className={perc > 100 && "finished"} />
        )}
        {hasRank ? (
          <VaultItemDetailsContainer>
            {hasRank && (
              <img
                className={"viper-rank"}
                src={rankIcon}
                height={32}
                width={32}
                alt={hasRank?.value}
                title={hasRank?.value}
              />
            )}
            <p>{name}</p>
            {type !== "staked" ? (
              <p style={{ fontSize: 14, fontWeight: 400 }}>
                {rankSnek} $SNEK / Day
              </p>
            ) : (
              <p style={{ fontSize: 14, fontWeight: 400 }}>{earnt} $SNEK</p>
            )}
          </VaultItemDetailsContainer>
        ) : (
          <VaultItemDetailsContainer>
            <p>{name}</p>
            <a
              href="https://factions.zenjinviperz.io"
              target="_blank"
              rel="noreferrer"
            >
              Select Faction First
            </a>
          </VaultItemDetailsContainer>
        )}
      </ViperContainer>
    </>
  );
};

export default Viper;

const ViperImage = styled.img`
  height: 155px;
  object-fit: contain;
  width: 155px;

  @media only screen and (min-width: 375px) {
    height: 162px;
    width: 162px;
  }

  @media only screen and (min-width: 414px) {
    height: 182px;
    width: 182px;
  }

  @media only screen and (min-width: 415px) {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
`;

const ViperContainer = styled.div`
  background: #fff;
  /* border: 2px solid transparent; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  overflow: hidden;
  padding: 0;
  position: relative;

  .progress {
    border-radius: 0;
    height: 4px;

    &.finished {
      .progress-bar {
        background: #006122;
      }
    }
  }

  .progress-bar {
    background: #eb4b44;
  }

  .viper-faction {
    left: 8px;
    position: absolute;
    top: 8px;
  }

  .viper-rank {
    display: none;
    position: absolute;
    right: 8px;
    top: 16px;

    @media only screen and (min-width: 414px) {
      display: block;
    }
  }
`;

const VaultItemDetailsContainer = styled.div`
  padding: 12px;
  position: relative;

  a {
    color: #666;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
  }

  p {
    color: #222;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  button {
    background: #eb4b44;
    border: 0;
    border-radius: 30px;
    box-shadow: none;
    color: #231f3d;
    padding: 6px 24px;

    &:disabled {
      background: #eee;
      color: #999;
    }
  }
`;
