import { ProgramAccount } from "@project-serum/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useCallback, useReducer, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

import toast from "react-hot-toast";

import Header from "components/Header";
import Hero from "components/Hero";
import BattleMission from "components/BattleMission";
import Loading from "components/Loading";
import Viper from "components/Viper";

import {
  useGetSnekEarn,
  useStakedFarmers,
  useStakedNFTs,
} from "functions/hooks";
import { claimAll } from "functions/allFunctions";

export const farmOneId = process.env.REACT_APP_FARM_ID_ONE;
export const farmTwoId = process.env.REACT_APP_FARM_ID_TWO;
export const farmThreeId = process.env.REACT_APP_FARM_ID_THREE;
export const farmFourId = process.env.REACT_APP_FARM_ID_FOUR;

const Missions = ({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: Function;
}) => {
  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction, signAllTransactions } =
    useWallet();

  const [selectedNft, setSelectedNft] = useState(null);

  const [refreshHandle, forceRefresh] = useReducer((x) => x + 1, 0);

  const stakedFarmers: ProgramAccount<any>[] = useStakedFarmers(refreshHandle);
  const { stakedNfts } = useStakedNFTs(stakedFarmers, refreshHandle);

  const totalNfts = stakedNfts.length;

  const { snek } = useGetSnekEarn(stakedNfts);

  let collator = new Intl.Collator(undefined, { numeric: true });

  const claimAllAction = useCallback(async () => {
    if (stakedNfts && publicKey) {
      const id = toast.loading("Claiming all $SNEK...");

      setLoading(true);

      await claimAll(
        new PublicKey(process.env.REACT_APP_FARM_ID_FOUR ?? ""),
        connection,
        // @ts-ignore
        wallet,
        publicKey,
        sendTransaction,
        signAllTransactions,
        stakedNfts
      );

      toast.success("All $SNEK Claimed.", { id });
      setLoading(false);
    }
  }, [
    connection,
    publicKey,
    sendTransaction,
    setLoading,
    signAllTransactions,
    stakedNfts,
    wallet,
  ]);

  return (
    <>
      {loading && <Loading type="full" />}
      <PageWrapper>
        <Header />
        {!selectedNft && <Hero publicKey={publicKey} />}
        {publicKey && (
          <HeroContainer>
            <Container>
              {selectedNft && (
                <BattleMission
                  loading={loading}
                  refreshAll={forceRefresh}
                  selectedNft={selectedNft}
                  stakeNftAction={() => console.log("stake")}
                  setLoading={setLoading}
                  setSelectedNft={setSelectedNft}
                  totalNfts={totalNfts}
                  // filledBottles={filledBottles}
                />
              )}
              <VaultsContainer>
                <RefreshButton>
                  <button className="refresh" onClick={() => forceRefresh()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>
                </RefreshButton>
                <SnekEarn>
                  <p>Earning: {snek} $SNEK/DAY</p>
                  {totalNfts > 9 && (
                    <button
                      onClick={() => claimAllAction()}
                      style={{ background: "#fff" }}
                    >
                      Claim All $SNEK
                    </button>
                  )}
                </SnekEarn>
                <CustomTabs defaultActiveKey="active">
                  <Tab
                    eventKey="active"
                    title={`Active (${stakedNfts?.length ?? 0})`}
                  >
                    {stakedNfts && (
                      <VaultWrapper>
                        <VaultContainer>
                          {stakedNfts
                            ?.sort((a, b) =>
                              collator.compare(a?.json?.name, b?.json?.name)
                            )
                            .map((nft) => {
                              return (
                                <Viper
                                  key={nft?.mint?.toString()}
                                  type={"staked"}
                                  nft={nft}
                                  selectedNft={selectedNft}
                                  setSelectedNft={setSelectedNft}
                                />
                              );
                            })}
                        </VaultContainer>
                      </VaultWrapper>
                    )}
                  </Tab>
                </CustomTabs>
              </VaultsContainer>
            </Container>
          </HeroContainer>
        )}
      </PageWrapper>
    </>
  );
};

export default Missions;

const PageWrapper = styled.div`
  margin-bottom: auto;
  padding-top: 150px;
`;

const HeroContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
`;

const VaultsContainer = styled.div`
  margin: 50px auto;
  min-height: 500px;
  position: relative;
  z-index: 5;
`;

const RefreshButton = styled.div`
  left: 0;
  position: absolute;
  top: 12px;

  button {
    background: transparent;
    border: 0;
    box-shadow: 0;
    padding: 0;
    transition: all 0.3s ease 0s;

    &:hover {
      transform: rotate(180deg);
    }
  }
`;

const SnekEarn = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  top: -20px;

  @media only screen and (min-width: 768px) {
    left: auto;
    right: 0;
    top: 10px;
  }

  p {
    font-size: 14px;
    line-height: 1;
    margin: 0;
    position: relative;
    top: 1px;

    @media only screen and (min-width: 768px) {
      margin-right: 16px;
    }
  }

  button {
    background: #eb4b44;
    border: 0;
    border-radius: 20px;
    color: #231f3d;
    cursor: pointer;
    display: none;
    font-size: 14px;
    height: 33px;
    line-height: 1;
    padding: 6px 24px;
    text-transform: capitalize;
    transition: all 0.2s ease 0s;

    @media only screen and (min-width: 768px) {
      align-items: center;
      display: flex;
    }

    &:first-of-type {
      margin-right: 8px;
    }
  }
`;

const CustomTabs = styled(Tabs)`
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 30px;
  padding: 10px 0px;
  padding-left: 36px;
  width: 100%;

  .nav-item {
    border: medium none;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    padding: 6px 0;
    text-transform: capitalize;
    transition: all 0.2s ease 0s;

    &.active {
      background: #eb4b44;
      color: #231f3d;
      padding: 6px 24px;
    }
  }
`;

const VaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const VaultContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    grid-gap: 46px;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    margin: 0;
  }

  @media only screen and (min-width: 1024px) {
    grid-gap: 30px;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, minmax(0px, 1fr));
  }
`;
