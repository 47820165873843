// import { useMutation } from "@apollo/client";
import { useWinNft, useWinPoints, useWinSnek } from "functions/hooks";
// import { SetPoints } from "functions/queries";
// import { transferNft, transferSnek } from "functions/transfer";
import { useReducer, useState } from "react";
// import { useReducer } from "react";
import { Modal } from "react-bootstrap";
// import toast from "react-hot-toast";
import styled from "styled-components";
// import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import SnakeChestBegin from "../images/begin800.gif";
import SnakeChestAnimate from "../images/white.gif";
import SnakeChestEnd from "../images/end800.gif";
import SnakeChestYellow from "../images/yelloww.gif";
import SnakeChestRed from "../images/redd.gif";

const ClaimModal = ({
  show,
  handleClose,
  isWinner,
  selectedNft,
  refreshAll,
  setSelectedNft,
  newPoints,
  totalNfts,
}: {
  show: boolean;
  handleClose: () => void;
  isWinner: boolean;
  selectedNft: any;
  refreshAll: Function;
  setSelectedNft: Function;
  newPoints: number;
  totalNfts: number;
}) => {
  // const { connection } = useConnection();
  // const { publicKey, sendTransaction } = useWallet();

  const handleCloseAction = () => {
    handleClose();
  };

  const [refreshHandle] = useReducer((x) => x + 1, 0);
  // const [setPoints] = useMutation(SetPoints);

  // const [loading, setLoading] = useState(false);
  // const [claimed, setClaimed] = useState(false);
  const [opened, setOpened] = useState(false);
  const [opening, setOpening] = useState(false);

  const [chestState, setChestState] = useState("begin");

  // win points
  const { points } = useWinPoints(isWinner, refreshHandle);
  // win snek
  const { isSnekWinner, snek } = useWinSnek(isWinner, refreshHandle);
  // win nft
  const { isNftWinner, nft } = useWinNft(isWinner, refreshHandle, totalNfts);

  // const claimAction = async () => {
  //   const id = toast.loading("Claiming Items...");
  //   setLoading(true);

  //   let hash = selectedNft.json.name.substring(
  //     selectedNft.json.name.indexOf("#") + 1
  //   );

  //   const totalPoints = Number(newPoints) + Number(points ?? 50);

  //   try {
  //     const { data: result } = await setPoints({
  //       variables: {
  //         hash,
  //         points: totalPoints.toString(),
  //       },
  //     });

  //     if (result?.setPoints?.errors?.length) {
  //       setLoading(false);
  //       toast.error(result?.setPoints?.errors[0].message, {
  //         id,
  //       });
  //     } else {
  //       if (isNftWinner) {
  //         const status = await transferNft(
  //           connection,
  //           publicKey,
  //           nft,
  //           sendTransaction
  //         );
  //         if (status) {
  //           if (status.err !== null) {
  //             setLoading(false);
  //             toast.error(
  //               "Something went wrong, please open a ticket on Discord.",
  //               {
  //                 id,
  //               }
  //             );
  //           } else {
  //             setLoading(false);
  //             toast.success(`Items Claimed.`, { id });
  //             setSelectedNft(null);
  //             await refreshAll();
  //             refreshAll();
  //             setClaimed(true);
  //           }
  //         }
  //       } else if (isSnekWinner) {
  //         try {
  //           const status = await transferSnek(
  //             connection,
  //             publicKey,
  //             snek,
  //             sendTransaction
  //           );
  //           if (status) {
  //             if (status.err !== null) {
  //               setLoading(false);
  //               toast.error(
  //                 "Something went wrong, please open a ticket on Discord.",
  //                 {
  //                   id,
  //                 }
  //               );
  //             } else {
  //               setLoading(false);
  //               toast.success(`Items Claimed.`, { id });
  //               setSelectedNft(null);
  //               await refreshAll();
  //               refreshAll();
  //               setClaimed(true);
  //             }
  //           }
  //         } catch (error) {
  //           setLoading(false);
  //           toast.error(error.message, {
  //             id,
  //           });
  //         }
  //       } else {
  //         setLoading(false);
  //         toast.success(`Items Claimed.`, { id });
  //         setSelectedNft(null);
  //         await refreshAll();
  //         refreshAll();
  //         setClaimed(true);
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("Something went wrong, please open a ticket on Discord.", {
  //       id,
  //     });
  //   }
  // };

  return (
    <Modal show={show} onHide={handleCloseAction} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Zenjin Chest</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div style={{ position: "relative" }}>
          {opened && (
            <LabelsContainer>
              <p>{!false ? "The Zenjin Chest contains" : "You Claimed"}</p>
              <Labels>
                <Label>{points ?? 50} Points</Label>
                {isNftWinner ? (
                  <Label>{nft?.json?.name ?? "Zenjin Viper"}</Label>
                ) : (
                  isSnekWinner && <Label>{snek} $SNEK</Label>
                )}
              </Labels>
            </LabelsContainer>
          )}
          {chestState === "end" ? (
            <img
              src={SnakeChestEnd}
              alt="Zenjin Chest"
              style={{ width: "100%" }}
            />
          ) : isNftWinner && chestState === "animate" ? (
            <img
              src={SnakeChestRed}
              alt="Zenjin Chest"
              style={{ width: "100%" }}
            />
          ) : isSnekWinner && chestState === "animate" ? (
            <img
              src={SnakeChestYellow}
              alt="Zenjin Chest"
              style={{ width: "100%" }}
            />
          ) : chestState === "animate" ? (
            <img
              src={SnakeChestAnimate}
              alt="Zenjin Chest"
              style={{ width: "100%" }}
            />
          ) : (
            <img
              src={SnakeChestBegin}
              alt="Zenjin Chest"
              style={{ width: "100%" }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        {!opened ? (
          <button
            className="btn btn-primary"
            onClick={() => {
              setOpening(true);
              setChestState("animate");
              setTimeout(() => {
                setOpening(false);
                setOpened(true);
                setChestState("end");
              }, 2500);
            }}
          >
            {!opening ? "Open Chest" : "Opening..."}
          </button>
        ) : !opening && opened && !false ? (
          <button
            className="btn btn-primary"
            disabled={opening}
            // onClick={() => claimAction()}
          >
            {!false ? "Claim" : "Claiming..."}
          </button>
        ) : (
          false && (
            <button className="btn btn-secondary" onClick={handleCloseAction}>
              Close
            </button>
          )
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimModal;

const LabelsContainer = styled.div`
  bottom: 20px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;

  p {
    text-align: center;
  }
`;

const Labels = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Label = styled.li`
  align-items: center;
  background: #fff;
  border-radius: 15px;
  color: #231f3d;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 15px;
  padding-right: 15px;
`;
