import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

const MissionItem = ({
  missionSlot,
  loading,
  selectedNft,
  stakeNftAction,
  unstakeNftAction,
  farmId,
  title = "Mission One",
  cost = 0,
  earned = 0,
  duration = 0,
}: {
  missionSlot: string;
  loading: boolean;
  selectedNft;
  stakeNftAction: Function;
  unstakeNftAction: (points: any) => Promise<void>;
  farmId: string;
  title: string;
  cost: number;
  earned: number;
  duration: number;
}) => {
  const { connection } = useConnection();

  const [missionSig, setMissionSig] = useState(null);

  const endMission = dayjs
    .unix(selectedNft?.farmer?.account?.minStakingEndsTs.toString())
    .add(duration, "days");

  useEffect(() => {
    async function fetchTransactionDate() {
      try {
        if (selectedNft?.farmer) {
          const sigs = await connection.getSignaturesForAddress(
            new PublicKey(selectedNft?.farmer?.publicKey?.toString()),
            {
              limit: 1,
            }
          );

          const trans = await connection.getParsedTransaction(
            sigs[0].signature
          );

          if (
            trans &&
            trans?.meta?.logMessages?.includes(
              "Program log: Instruction: Stake"
            )
          ) {
            setMissionSig(sigs[0].memo ? sigs[0].memo.substring(14) : null);
          } else {
            const newSigs = await connection.getSignaturesForAddress(
              new PublicKey(selectedNft?.farmer?.publicKey?.toString()),
              {
                limit: 10,
              }
            );

            let newArray = [];

            for (var i = 0; i < newSigs.length; i++) {
              var value = newSigs[i];
              const trans = await connection.getParsedTransaction(
                value.signature
              );

              if (
                trans &&
                trans?.meta?.logMessages?.includes(
                  "Program log: Instruction: Stake"
                )
              ) {
                newArray.push(value);
              }
            }

            const data = newArray[0];

            if (data) {
              setMissionSig(data.memo.substring(14));
            } else {
              setMissionSig(sigs[0].memo.substring(14));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchTransactionDate();
  }, [connection, selectedNft]);

  const mission =
    selectedNft?.farmer?.account?.farm?.toString() ===
    process.env.REACT_APP_FARM_ID_ONE
      ? "one"
      : selectedNft?.farmer?.account?.farm?.toString() ===
        process.env.REACT_APP_FARM_ID_TWO
      ? "two"
      : selectedNft?.farmer?.account?.farm?.toString() ===
        process.env.REACT_APP_FARM_ID_FOUR
      ? "three"
      : "";

  return (
    <MissionsListItemActive
      onClick={() =>
        !selectedNft?.farmer
          ? toast.error("Missions closed, war is coming!")
          : unstakeNftAction(earned)
      }
      className={
        missionSig
          ? missionSig !== missionSlot && "cursor"
          : mission !== missionSlot && "cursor"
      }
      disabled={loading}
    >
      <div>
        <div>
          <p className="mission-title">{title}</p>
        </div>
        <div style={{ display: "flex" }}>
          <span>Cost: {cost}</span>
          <span>Points Earned: {earned}</span>
          <span>
            {!selectedNft?.farmer ||
            (selectedNft?.farmer && missionSig
              ? missionSig !== missionSlot
              : mission !== missionSlot)
              ? `Duration: ${duration} Days`
              : missionSig === missionSlot &&
                endMission.diff(new Date(), "seconds") < 0
              ? "Click to Finish"
              : mission === missionSlot &&
                endMission.diff(new Date(), "seconds") < 0
              ? "Click to Finish"
              : "Click to Finish"}
          </span>
        </div>
      </div>
      <div></div>
    </MissionsListItemActive>
  );
};

export default MissionItem;

const MissionsListItemActive = styled.button`
  align-items: center;
  background: #71777b;
  border: 2px solid #b18b45;
  border-radius: 2px;
  box-shadow: 2px 2px 0 #101112;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 4px;
  padding-top: 8px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 74px;
    padding: 0;
    padding-left: 24px;
    padding-right: 8px;
  }

  &.cursor {
    border: 2px solid #4e5256;
  }

  .mission-title {
    color: #fff;
    font-family: "ROTORcap Neue Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    margin-bottom: 4px;
    text-align: left;
    text-shadow: 0px 1px 1px #4e5256;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  span {
    color: #ffffff !important;
    font-family: "ROTORcap Neue Bold" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 8px !important;
    line-height: 8px !important;
    margin-bottom: 0 !important;
    opacity: 0.8 !important;
    text-shadow: 0px 1px 1px #4e5256 !important;

    @media only screen and (min-width: 768px) {
      font-size: 12px !important;
      line-height: 12px !important;
    }

    &:not(:last-child) {
      margin-right: 12px;

      @media only screen and (min-width: 768px) {
        margin-right: 28px;
      }
    }
  }

  img {
    @media only screen and (max-width: 768px) {
      height: 40px;
    }
  }
`;
