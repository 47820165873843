import RankOne from "../images/ranks/1.png";
import RankTwo from "../images/ranks/2.png";
import RankThree from "../images/ranks/3.png";
import RankFour from "../images/ranks/4.png";
import RankFive from "../images/ranks/5.png";
import RankSix from "../images/ranks/6.png";
import RankSeven from "../images/ranks/7.png";
import RankEight from "../images/ranks/8.png";
import RankNine from "../images/ranks/9.png";
import RankTen from "../images/ranks/10.png";
import RankEleven from "../images/ranks/11.png";
import RankTwelve from "../images/ranks/12.png";
import RankThirteen from "../images/ranks/13.png";
import RankFourteen from "../images/ranks/14.png";
import { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import dayjs, { Dayjs } from "dayjs";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import { initGemFarm, NFTData, populateVaultNFTs } from "./staking";
import { ProgramAccount } from "@project-serum/anchor";
import {
  Token,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { programs } from "@metaplex/js";

const {
  metadata: { Metadata },
} = programs;

export const creatorId = process.env.REACT_APP_CREATOR_ID;
export const creatorIdTwo = process.env.REACT_APP_CREATOR_ID_AI;
export const farmOneId = process.env.REACT_APP_FARM_ID_ONE;
export const farmTwoId = process.env.REACT_APP_FARM_ID_TWO;
export const farmThreeId = process.env.REACT_APP_FARM_ID_THREE;
export const farmFourId = process.env.REACT_APP_FARM_ID_FOUR;

export function useGetRank(rank) {
  let rankId = 1;
  let rankSnek = 5;
  let rankIcon;
  let rankNextPoints = 0;
  let rankNextIcon;

  if (rank === "Scout") {
    rankId = 1;
    rankSnek = 5;
    rankNextPoints = 500;
    rankIcon = RankOne;
    rankNextIcon = RankTwo;
  } else if (rank === "Grunt") {
    rankId = 2;
    rankSnek = 5;
    rankNextPoints = 750;
    rankIcon = RankTwo;
    rankNextIcon = RankThree;
  } else if (rank === "Sergeant") {
    rankId = 3;
    rankSnek = 5;
    rankNextPoints = 1000;
    rankIcon = RankThree;
    rankNextIcon = RankFour;
  } else if (rank === "Senior Sergeant") {
    rankId = 4;
    rankSnek = 5;
    rankNextPoints = 1500;
    rankIcon = RankFour;
    rankNextIcon = RankFive;
  } else if (rank === "First Sergeant") {
    rankId = 5;
    rankSnek = 5;
    rankNextPoints = 2000;
    rankIcon = RankFive;
    rankNextIcon = RankSix;
  } else if (rank === "Stone Guard") {
    rankId = 6;
    rankSnek = 7;
    rankNextPoints = 3000;
    rankIcon = RankSix;
    rankNextIcon = RankSeven;
  } else if (rank === "Blood Guard") {
    rankId = 7;
    rankSnek = 7;
    rankNextPoints = 4000;
    rankIcon = RankSeven;
    rankNextIcon = RankEight;
  } else if (rank === "Legionnaire") {
    rankId = 8;
    rankSnek = 7;
    rankNextPoints = 6000;
    rankIcon = RankEight;
    rankNextIcon = RankNine;
  } else if (rank === "Centurion") {
    rankId = 9;
    rankSnek = 7;
    rankNextPoints = 8000;
    rankIcon = RankNine;
    rankNextIcon = RankTen;
  } else if (rank === "Champion") {
    rankId = 10;
    rankSnek = 10;
    rankNextPoints = 12000;
    rankIcon = RankTen;
    rankNextIcon = RankEleven;
  } else if (rank === "Lieutenant General") {
    rankId = 11;
    rankSnek = 10;
    rankNextPoints = 16000;
    rankIcon = RankEleven;
    rankNextIcon = RankTwelve;
  } else if (rank === "General") {
    rankId = 12;
    rankSnek = 10;
    rankNextPoints = 20000;
    rankIcon = RankTwelve;
    rankNextIcon = RankThirteen;
  } else if (rank === "Warlord") {
    rankId = 13;
    rankSnek = 12;
    rankIcon = RankThirteen;
    rankNextPoints = 25000;
    rankNextIcon = RankFourteen;
  } else if (rank === "High Warlord") {
    rankId = 14;
    rankSnek = 15;
    rankIcon = RankFourteen;
  }

  return { rankId, rankSnek, rankNextPoints, rankIcon, rankNextIcon };
}

export function useGetSnekEarn(nfts) {
  let snek = 0;

  nfts?.forEach((nft) => {
    let rankSnek = 0;
    const rank = nft?.json?.attributes?.find((el) => el.trait_type === "Rank");

    if (rank?.value === "Scout") {
      rankSnek = 5;
    } else if (rank?.value === "Grunt") {
      rankSnek = 5;
    } else if (rank?.value === "Sergeant") {
      rankSnek = 5;
    } else if (rank?.value === "Senior Sergeant") {
      rankSnek = 5;
    } else if (rank?.value === "First Sergeant") {
      rankSnek = 5;
    } else if (rank?.value === "Stone Guard") {
      rankSnek = 7;
    } else if (rank?.value === "Blood Guard") {
      rankSnek = 7;
    } else if (rank?.value === "Legionnaire") {
      rankSnek = 7;
    } else if (rank?.value === "Centurion") {
      rankSnek = 7;
    } else if (rank?.value === "Champion") {
      rankSnek = 10;
    } else if (rank?.value === "Lieutenant General") {
      rankSnek = 10;
    } else if (rank?.value === "General") {
      rankSnek = 10;
    } else if (rank?.value === "Warlord") {
      rankSnek = 12;
    } else if (rank?.value === "High Warlord") {
      rankSnek = 15;
    }
    snek = snek + rankSnek;
  });
  return { snek };
}

export const useGetIsWinner = (refreshHandle: any, totalNfts: number) => {
  const [isWinner, setIsWinner] = useState<boolean | null>();

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      var precision = 100; // 2 decimals
      var randomnum =
        Math.floor(
          Math.random() * (10 * precision - 1 * precision) + 1 * precision
        ) /
        (1 * precision);

      if (totalNfts > 9 && randomnum > 6.66) {
        setIsWinner(true);
      } else if (totalNfts < 10 && randomnum > 4) {
        setIsWinner(true);
      } else {
        setIsWinner(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [refreshHandle, totalNfts]);

  return isWinner;
};

export const useWinPoints = (isWinner: boolean, refreshHandle: any) => {
  const [isPointsWinner, setIsPointsWinner] = useState<boolean | null>();
  const [points, setPoints] = useState<number | null>();

  useEffect(() => {
    let isCancelled = false;
    if (!isWinner) return;

    if (!isCancelled) {
      setIsPointsWinner(true);

      var pointsNum =
        Math.floor(Math.random() * (10 * 1 - 1 * 1) + 1 * 1) / (1 * 1);

      if (pointsNum < 5) {
        setPoints(pointsNum * 50);
      } else if (pointsNum < 7.5) {
        setPoints(pointsNum * 40);
      } else if (pointsNum < 10) {
        setPoints(pointsNum * 30);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [isWinner, refreshHandle]);

  return { isPointsWinner, points };
};

export const useWinSnek = (isWinner: boolean, refreshHandle: any) => {
  const [isSnekWinner, setIsSnekWinner] = useState<boolean | null>();
  const [snek, setSnek] = useState<number | null>();

  useEffect(() => {
    let isCancelled = false;
    if (!isWinner) return;

    if (!isCancelled) {
      var precision = 100; // 2 decimals
      var randomnum =
        Math.floor(
          Math.random() * (100 * precision - 1 * precision) + 1 * precision
        ) /
        (1 * precision);

      if (randomnum > 66) {
        setIsSnekWinner(true);

        var pointsNum =
          Math.floor(Math.random() * (20 * 1 - 1 * 1) + 1 * 1) / (1 * 1);

        if (pointsNum < 10) {
          setSnek(pointsNum * 3);
        } else if (pointsNum < 15) {
          setSnek(pointsNum * 2);
        } else if (pointsNum < 20) {
          setSnek(pointsNum * 1);
        }
      } else {
        setIsSnekWinner(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [isWinner, refreshHandle]);

  return { isSnekWinner, snek };
};

// export const useWinSol = (isWinner: boolean) => {
//   const [isSolWinner, setIsSolWinner] = useState<boolean | null>();
//   const [sol, setSol] = useState<number | null>();

//   useEffect(() => {
//     let isCancelled = false;
//     if (!isWinner) return;

//     if (!isCancelled) {
//       console.log("win sol");
//       var precision = 100; // 2 decimals
//       var randomnum =
//         Math.floor(
//           Math.random() * (100 * precision - 1 * precision) + 1 * precision
//         ) /
//         (1 * precision);

//       // console.log(randomnum);

//       if (randomnum > 80) {
//         setIsSolWinner(true);
//       } else {
//         setIsSolWinner(false);
//       }
//     }

//     return () => {
//       isCancelled = true;
//     };
//   }, [isWinner]);

//   return { isSolWinner, sol };
// };

export const useWinNft = (
  isWinner: boolean,
  refreshHandle: any,
  totalNfts: number
) => {
  const { connection } = useConnection();

  const [isNftWinner, setIsNftWinner] = useState<boolean | null>();
  const [nft, setNft] = useState<NFTData | null>();

  useEffect(() => {
    (async () => {
      const walletNfts = await Metadata.findDataByOwner(
        connection,
        new PublicKey("ZVP8miHHQeNbCEfUrk7ai4BgdvamdDxpppRe99BZD17")
      );

      let isCancelled = false;

      if (!isWinner) return;

      if (walletNfts.length === 0) {
        setIsNftWinner(false);
      }

      if (!isCancelled) {
        var precision = 100; // 2 decimals
        var randomnum =
          Math.floor(
            Math.random() * (100 * precision - 1 * precision) + 1 * precision
          ) /
          (1 * precision);

        if (randomnum < 5) {
          const nftData = walletNfts[0] ?? null;

          if (
            nftData &&
            nftData.data.creators &&
            nftData.data.creators[0]?.verified &&
            nftData.data?.creators[0]?.address === creatorIdTwo
          )
            try {
              const associatedAddress = await Token.getAssociatedTokenAddress(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                new PublicKey(nftData.mint),
                new PublicKey("ZVP8miHHQeNbCEfUrk7ai4BgdvamdDxpppRe99BZD17")
              );
              setNft({
                mint: new PublicKey(nftData.mint),
                data: nftData.data,
                json: await fetch(nftData.data.uri, { cache: "no-cache" }).then(
                  (e) => e.json()
                ),
                source: associatedAddress,
                creator: new PublicKey(nftData?.data?.creators[0]?.address),
              });
            } catch (e) {
              console.log(e);
            }

          if (totalNfts) {
            if (totalNfts > 9 && randomnum < 2.5) {
              setIsNftWinner(true);
            } else {
              setIsNftWinner(false);
            }
          } else {
            setIsNftWinner(true);
          }
        } else {
          setIsNftWinner(false);
        }
      }

      return () => {
        isCancelled = true;
      };
    })();
  }, [connection, isWinner, refreshHandle, totalNfts]);

  return { isNftWinner, nft };
};

export const useUnstakedNfts = (refreshHandle?: any) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const [unstakedNfts, setUnstakedNfts] = useState<NFTData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!publicKey) return;

      setIsLoading(true);
      const walletNfts = await Metadata.findDataByOwner(connection, publicKey);

      const nfts = [];
      for (let nft of walletNfts)
        if (
          nft.data.creators &&
          nft.data.creators[0]?.verified &&
          nft?.data?.creators[0]?.address === creatorId
        )
          try {
            const associatedAddress = await Token.getAssociatedTokenAddress(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              new PublicKey(nft.mint),
              publicKey
            );

            nfts.push({
              mint: new PublicKey(nft.mint),
              data: nft.data,
              json: await fetch(nft.data.uri, { cache: "no-cache" }).then((e) =>
                e.json()
              ),
              source: associatedAddress,
              creator: new PublicKey(nft?.data?.creators[0]?.address),
            });
          } catch (e) {
            console.log(e);
          }

      let collator = new Intl.Collator(undefined, { numeric: true });
      nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));
      setUnstakedNfts(nfts);
      setIsLoading(false);
    })();
  }, [publicKey, connection, refreshHandle]);

  return { unstakedNfts, unstakedNftsIsLoading: isLoading };
};

export const useStakedNFTs = (
  stakedFarmers: ProgramAccount<any>[],
  refreshHandle?: any
) => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const [isLoading, setIsLoading] = useState(false);
  const [stakedNfts, setStakedNfts] = useState<NFTData[]>([]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      if (!publicKey) return;

      setIsLoading(true);

      let collator = new Intl.Collator(undefined, { numeric: true });

      if (stakedFarmers) {
        const formatCurrentStakingNft: NFTData[] = [];
        const farmers = stakedFarmers;

        if (farmers !== null) {
          for (const farmer of farmers) {
            const currentStakingNft = await populateVaultNFTs(
              connection,
              wallet!.adapter as SignerWalletAdapter,
              farmer
            );

            if (currentStakingNft) {
              const associatedAddress = await Token.getAssociatedTokenAddress(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                new PublicKey(currentStakingNft[0].mint),
                publicKey
              );

              try {
                formatCurrentStakingNft.push(
                  ...currentStakingNft?.map((e: any) => {
                    return {
                      mint: e.mint,
                      data: e.onchainMetadata.data,
                      json: e.externalMetadata,
                      source: associatedAddress,
                      creator: new PublicKey(
                        e.onchainMetadata.data.creators[0]?.address
                      ),
                      farmer: farmer,
                      isStaked: true,
                    };
                  })
                );
              } catch (ex) {
                console.log(ex);
              }
            }
          }
        }

        setStakedNfts(
          formatCurrentStakingNft.sort((a, b) =>
            collator.compare(a.data.name, b.data.name)
          )
        );

        setIsLoading(false);
      }
    })();
  }, [connection, publicKey, wallet, refreshHandle, stakedFarmers]);

  return { stakedNfts, stakedNftsIsLoading: isLoading };
};

export const useStakedFarmers = (refreshHandle?: any) => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const [farmers, setFarmers] = useState(new Array<ProgramAccount>());

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      if (!publicKey) return;

      // Remove async from here
      const gf = initGemFarm(
        connection,
        wallet!.adapter as SignerWalletAdapter
      );

      if (farmFourId) {
        const data = await gf.fetchAllFarmerPDAs(
          new PublicKey(farmFourId),
          publicKey
        );

        setFarmers(data);
      }

      if (farmOneId) {
        const data = await gf.fetchAllFarmerPDAs(
          new PublicKey(farmOneId),
          publicKey
        );

        setFarmers((farmers) => {
          return [...farmers, ...data];
        });
      }

      if (farmTwoId) {
        const data = await gf.fetchAllFarmerPDAs(
          new PublicKey(farmTwoId),
          publicKey
        );

        setFarmers((farmers) => {
          return [...farmers, ...data];
        });
      }
    })();
  }, [connection, publicKey, wallet, refreshHandle]);

  return farmers;
};

export const useTokensEarnt = (farmer: any, rankEarn: number, type: string) => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const [dateStaked, setDateStaked] = useState<Dayjs>(dayjs());
  const [earnt, setEarnt] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      if (!publicKey) return;
      if (type !== "staked") return;

      try {
        if (farmer) {
          const sigs = await connection.getSignaturesForAddress(
            new PublicKey(farmer?.publicKey?.toString()),
            {
              limit: 1,
            }
          );

          const trans = await connection.getParsedTransaction(
            sigs[0].signature
          );

          if (
            trans &&
            (trans?.meta?.logMessages?.includes(
              "Program log: Instruction: Stake"
            ) ||
              trans?.meta?.logMessages?.includes(
                "Program log: Instruction: Claim"
              ))
          ) {
            setDateStaked(dayjs.unix(sigs[0]?.blockTime ?? 0));
          } else {
            const newSigs = await connection.getSignaturesForAddress(
              new PublicKey(farmer?.publicKey?.toString()),
              {
                limit: 10,
              }
            );

            let newArray = [];

            for (var i = 0; i < newSigs.length; i++) {
              var value = newSigs[i];
              const trans = await connection.getParsedTransaction(
                value.signature
              );

              if (
                trans &&
                (trans?.meta?.logMessages?.includes(
                  "Program log: Instruction: Stake"
                ) ||
                  trans?.meta?.logMessages?.includes(
                    "Program log: Instruction: Claim"
                  ))
              ) {
                newArray.push(newSigs[i]);
              }
            }

            const data = newArray[0];

            if (data) {
              setDateStaked(dayjs.unix(data.blockTime ?? 0));
            } else {
              setDateStaked(dayjs.unix(sigs[0]?.blockTime ?? 0));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [connection, farmer, publicKey, wallet, rankEarn, type]);

  useEffect(() => {
    if (type !== "staked") return;
    let isCancelled = false;

    if (dateStaked && !isCancelled) {
      const diff = dayjs(new Date()).diff(dateStaked, "seconds");
      setEarnt(diff * 0.000011574 * rankEarn);
    }

    return () => {
      isCancelled = true;
    };
  }, [dateStaked, rankEarn, type]);

  return Number(earnt.toFixed(3));
};
