import styled from "styled-components";

import { useGetRank } from "functions/hooks";

import BattleRank from "./BattleRank";

const BattleMissionViperDetails = ({
  currentPoints,
  rankName,
}: {
  currentPoints: number;
  rankName: string;
}) => {
  const { rankId, rankNextPoints, rankNextIcon } = useGetRank(rankName);

  return (
    <BattleMissionViperDetailsWrapper>
      <BattleMissionViperDetailsContainer>
        <div style={{ marginRight: 20 }}>
          <span>Rank</span>
          <p>{rankName}</p>
        </div>
        <BattleRank rankName={rankName} size={64} />
      </BattleMissionViperDetailsContainer>
      {rankId !== 14 && (
        <BattleMissionViperDetailsContainer>
          <div>
            <span>Points to next rank</span>
            <p>{rankNextPoints - (currentPoints ? currentPoints : 0)}</p>
          </div>
          <img src={rankNextIcon} alt={"Next Rank"} height={64} width={64} />
        </BattleMissionViperDetailsContainer>
      )}
    </BattleMissionViperDetailsWrapper>
  );
};

export default BattleMissionViperDetails;

const BattleMissionViperDetailsWrapper = styled.div`
  min-height: 210px;
`;

const BattleMissionViperDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;
