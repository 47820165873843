import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { HeroLink } from "components/Header";
import Missions from "containers/Missions";
import { useCallback, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";

import MELogo from "./images/melogo.png";

const App = () => {
  const [loading, setLoading] = useState(false);
  const network = WalletAdapterNetwork.Mainnet;

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  const onError = useCallback((error) => {
    toast.dismiss();
    toast.error(
      error.message ? `${error.name}: ${error.message}` : error.name,
      {
        duration: 3000,
      }
    );
    setLoading(false);
  }, []);

  return (
    <ConnectionProvider endpoint={process.env.REACT_APP_RPC_ID}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        <WalletModalProvider>
          <Missions loading={loading} setLoading={setLoading} />
          <Footer>
            <HeroLink
              href="http://www.magiceden.io/creators/ZenjinViperz"
              target="_blank"
            >
              <img src={MELogo} alt="Magic Eden" height={28} />
            </HeroLink>
            <HeroLink href="http://www.discord.gg/ZenjinViperz" target="_blank">
              <svg
                width="36"
                height="36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.31 0A3.699 3.699 0 0 1 33 3.708V36l-3.87-3.42-2.178-2.016-2.304-2.142.954 3.33H5.19a3.699 3.699 0 0 1-3.69-3.708V3.708A3.699 3.699 0 0 1 5.19 0h24.12Zm-6.948 23.508c3.978-.126 5.508-2.736 5.508-2.736 0-5.796-2.592-10.494-2.592-10.494-2.592-1.944-5.058-1.89-5.058-1.89l-.252.288c3.06.936 4.482 2.286 4.482 2.286a14.673 14.673 0 0 0-5.418-1.728 15.23 15.23 0 0 0-3.636.036l-.306.036c-.63.054-2.16.288-4.086 1.134-.666.306-1.062.522-1.062.522s1.494-1.422 4.734-2.358l-.18-.216s-2.466-.054-5.058 1.89c0 0-2.592 4.698-2.592 10.494 0 0 1.512 2.61 5.49 2.736 0 0 .666-.81 1.206-1.494-2.286-.684-3.15-2.124-3.15-2.124l.504.306.072.054.07.04.021.01.071.04c.45.252.9.45 1.314.612.738.288 1.62.576 2.646.774a12.61 12.61 0 0 0 4.662.018c.846-.144 1.71-.396 2.61-.774a10.3 10.3 0 0 0 2.07-1.062s-.9 1.476-3.258 2.142c.54.684 1.188 1.458 1.188 1.458Zm-8.37-8.406c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998.018-1.098-.81-1.998-1.836-1.998Zm6.57 0c-1.026 0-1.836.9-1.836 1.998 0 1.098.828 1.998 1.836 1.998 1.026 0 1.836-.9 1.836-1.998 0-1.098-.81-1.998-1.836-1.998Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </HeroLink>
            <HeroLink
              href="http://www.twitter.com/ZenjinViperz"
              target="_blank"
            >
              <svg
                width="36"
                height="36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path
                    d="M36 6.835a14.746 14.746 0 0 1-4.242 1.163 7.398 7.398 0 0 0 3.247-4.086 14.795 14.795 0 0 1-4.69 1.792 7.373 7.373 0 0 0-5.391-2.332c-4.768 0-8.273 4.449-7.195 9.067-6.137-.307-11.579-3.247-15.223-7.716-1.934 3.32-1.003 7.662 2.285 9.861a7.354 7.354 0 0 1-3.344-.924c-.08 3.422 2.372 6.623 5.924 7.335a7.403 7.403 0 0 1-3.336.126 7.391 7.391 0 0 0 6.9 5.129A14.85 14.85 0 0 1 0 29.31a20.909 20.909 0 0 0 11.322 3.318c13.713 0 21.46-11.582 20.993-21.97A15.036 15.036 0 0 0 36 6.836Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </HeroLink>
          </Footer>
        </WalletModalProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;
